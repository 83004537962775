body {
  background-color: white !important;
}

.conf-row {
  min-height: 100vh;
}

.conf-header {
  min-height: 10vh !important;
  background-color: black !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-link{
  /* font-family: Nunito Sans,sans-serif; */
  font-size: 20px;
  color: #fff;
  text-transform: none;
  font-weight: 400;
}

.logo{
  width: 55%;
}

.size-text{
  position: absolute;
  left: 90%;
  top: 3.5vh;
  color: white;
  font-weight: 700;
}


.conf-header img {
  height: 65%;
}

.left-container {
  width: 97%;
  min-height: 85vh;
}

.left-container .left-top {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 2vh;
}

.left-container .left-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.left-container .left-icon-container img {
  margin: 1vh 0px;
}

.left-container .bracelet-container {
  width: 95%;
  height: 20vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  /* overflow-y: hidden; */
}

.left-container .bracelet-container .bracelet {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left-container .leaf {
  width: 100%;
  height: auto;
  max-height: 10vh;
}

.left-container .categories-container {
  width: 100%;
  min-height: 17.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories-row {
  width: 52.5%;
  height: 100%;
}

.categories-card {
  height: 85%;
  width: 100%;
  background-color: white;
  margin: 7.5% 0;
  box-shadow: 1px 1px 2.5px 2.5px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10%;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
}

.categories-card img {
  height: 50%;
  width: auto;
}

.right-text-box {
  height: 50vh;
  padding: 0px 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.2s linear;
}

.right-text-box h4{
font-size: 17px;
}
.right-text-box h2{
font-size: 19px;
}

.right-text-box .selection-flow {
  max-height: 50vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.right-text-box p {
  font-weight: 600;
  font-size: 11px;
}

.right-text-box span {
  font-size: 9.5px;
}

.right-container {
  padding: 2vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-card {
  min-height: 75vh;
  margin: 0 1vh;
  width: 94%;
  border-radius: 50px;
  box-shadow: 1px 1px 6px 5px rgba(0, 0, 0, 0.1);
  z-index: 50;
  flex-direction: column;
}

.right-card .top-button-box {
  border-radius: 75px;
}

.right-card .top-button-box .top-button-left {
  height: 8vh;
  font-size: 20px;
  font-weight: 600;
  border-top-left-radius: 75px;
  text-align: center;
  display: flex;
  padding: 0px 25%;
  justify-content: space-evenly;
  align-items: center;
}

.right-card .top-button-box .top-button-left .top-button-collapse {
  transition: 0.2s ease;
}

.right-card .top-button-box .top-button-right {
  height: 8vh;
  font-size: 17px;
  border-top-right-radius: 75px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.right-card .top-button-box .top-button-right p {
  font-size: 23px;
  margin-top: 2vh;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.beats-container {
  padding: 0px 3vw 0px 5vw;
  margin: 3vh 0;
  height: 38vh;
  max-height: 45vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.beats-cards {
  width: 90%;
  display: flex;
  justify-content: center;
}

.beats-cards img {
  height: auto;
  width: 90%;
  margin: auto;
}

.item-details {
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 2s linear;
}

.item-details img {
  height: auto;
  width: 60%;
}

.item-details h3 {
  font-weight: 600;
  font-size: 1.5rem;
}

.item-details h4 {
  font-weight: 700;
  font-size: 1rem;
}

.item-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 99;
  width: 100%;
}

.item-top div {
  width: 100%;
}

.item-top img {
  width: 50%;
  height: 10vh;
  margin-right: 10%;
  margin-left: 20%;
}

.add-to-cart-container {
  /* height: 30%; */
  padding: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s linear;
}

.add-to-cart-container .add-to-cart-text-box {
  max-height: 30vh;
  margin: 0px 0px 3vh 0px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.add-to-cart-scroll-area {
  max-height: 45vh;
  min-height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
}

.add-to-cart-container .add-to-cart-text-row {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.bracelet-length{
  width: 70%;
  text-align: left;
  font-size: 12.5px;
}

.add-to-cart-container .add-to-cart-text-row div {
  display: flex;
  align-items: center;
}

.add-to-cart-container .add-to-cart-text-row div img {
  width: 2.5vw;
}

.add-to-cart-container .add-to-cart-text-row p {
  color: #a8a6a6;
  margin: 1vh;
}

.add-to-cart-container .add-to-cart-text-row .btns {
  height: 100%;
  width: 40px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 2px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.05);
}

.add-to-cart-container .add-to-cart-text-row .btns:hover {
  background-color: #ffffff;
  color: #000;
  border: 1px solid #000;
  transition: 0.2s ease;
}

.add-to-cart-container .add-to-cart-text-row .size {
  width: 50%;
  height: 100%;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  color: #000;
}
.add-to-cart-container .add-to-cart-text-row .size p {
  font-weight: 400;
  margin-left: 5px;
  opacity: 0.7;
  margin: 0px;
}

.add-to-cart-container hr {
  border: 0.5px solid #707070;
  width: 100%;
}

.add-to-cart-container .add-to-cart-colors-box {
  min-height: 10vh;
}

.add-to-cart-container .add-to-cart-colors-box p {
  color: #707070;
  font-weight: 500;
}

.add-to-cart-container .add-to-cart-colors-box div {
  display: flex;
  justify-content: space-between;
}

.image-text-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-text-row div {
  display: flex;
  align-items: center;
}

.image-text-row div .img {
  width: 3vw;
  overflow: hidden;
}

.image-text-row div .img img {
  width: 100%;
  transform: scale(1.5) !important;
}

.image-text-row p {
  color: #a8a6a6;
  margin: 0.5vh;
}

.bottom-buttons {
  background-color: black;
  height: 10%;
  width: 100;
  border-radius: 0px 0px 75px 75px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.bottom-buttons p {
  font-size: 17px;
  margin-top: 2vh;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: white;
}

.bottom-buttons h4 {
  font-size: 23px;
  margin-top: 2vh;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: white;
}

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 90;
}

.modal-bracelet {
  width: 100vw;
  height: 100vh !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-bracelet .leaf {
  width: 100%;
  height: auto;
  max-height: 15vh;
}

.modal-cross-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.selection-beats {
  width: 100%;
  overflow: hidden;
}

.selection-beats div{
  z-index: 99;
}

.selection-beats img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  transform: scale(1.2);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*# sourceMappingURL=braceletModal.css.map */

/* media query */

@media only screen and (max-width: 600px) {
  .categories-card{
height: 10vh;
font-size: 8px;
  }

  .image-text-row div .img {
    width: 10vw !important;
    overflow: hidden;
  }
  
  .image-text-row div .img img {
    width: 100%;
    transform: scale(1.5) !important;
  }

  .conf-header {
    background-color: transparent !important;
  }
  
  .categories-row {
    width: 100%;
    max-height: 60%;
  }

  .logo{
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: black;
  }
  .size-text{
    position: absolute;
    left: 72.5%;
    top: 2.5vh;
    color: white;
    font-weight: 700;
  }

  .logo img{
    height: 10vh;
  }

  .header-link{
    font-size: 16px;
  }

  .bracelet {
    min-width: 300%;
    margin-right: 12.5%;
    margin-left: 120%;
  }

  .left-container .left-top {
    border-bottom: none;
    padding-bottom: 1vh;
  }

  .top-button-right {
    font-size: 12px;
  }
  body{
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 800px) {
  .top-button-right {
    font-size: 10px;
  }

  .categories-row {
    width: 85%;
    height: 80%;
  }

  .top-button-right-small {
    font-size: 4px;
  }

  .bracelet {
    min-width: 200%;
  }

  .left-container .left-top {
    border-bottom: none;
    padding-bottom: 1.5vh;
  }
}

@media only screen and (max-width: 1000px) {
  .top-button-right {
    font-size: 10px;
  }

  .categories-row {
    width: 75%;
    height: 80%;
  }
}
