.landing-body{
    background-image: url("../../assets/images/bg.jpg") !important;
    width: 100%;
    height: 90vh;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
  }

  body{
    overflow-x: hidden;
    overflow-y: auto;
  }

 .layer{
     height: 100%;
     width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 7.5vh;
        overflow: hidden;
 } 
  .landing-card{
    height: 70vh;
    width: 70vh;
    border-radius: 100px;
    background-color: white;
    box-shadow: 5px 10px 5px 0px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5.5%;
    font-size: 22.5px;
    font-weight: 300;
    cursor: pointer;
  }
  
  .landing-card img{
    width: 60%;
  }

  .react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content,
    .react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
      opacity: 0.7;
  }

  @media only screen and (max-width: 600px) {
    .landing-body{
      background-image: url('https://cdn.shopify.com/s/files/1/0509/4978/3709/files/Rectangle_28_994fffdc-195d-4cc6-98fc-7d51da76a8d0.png?v=1625143150') !important;
    filter: grayscale(50%);
    }
    .layer{
      padding-top: 27.5%;
    }
    .landing-card{
      height: 40vh;
      width: 100vw;
      border-radius: 25px;
    }
    .landing-card p{
      font-size: 15px;
    }

    .react-3d-carousel{
      width: 100vw;
      position: absolute !important;
      left: 0px;
    }
    .react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content{
      /* width: 100vw !important; */
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  .react-3d-carousel .slider-container .slider-left div,
  .react-3d-carousel .slider-container .slider-right div {
    display: none !important;
  }
  }
  