body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal-wrap {
  background-color: #fff !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content{
  box-shadow: none !important;
}

.float{
	position:fixed;
	width:60px;
	height:26px;
	bottom:10px;
	right:10px;
	background-color:rgb(7, 7, 7);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top: 22px;
}